<template>
    <!-- 开通 / 激活 vip -->
    <div class="opena_ctivate">
        <el-tabs v-model="tabactive" type="card">
            <!-- 开通vip -->
            <el-tab-pane label="开通 VIP" name="1">
                <div class="vip_box">
                    <div class="vip_con">
                        <div class="cardbox">
                            <div class="cardimg"><img :src="cardinfo.CoverImageFileUrl" alt="" /></div>
                            <div class="cardmark">
                                <div class="cardtit">{{ cardinfo.ProductName }}</div>
                                <div class="price">价格<span>￥{{ cardinfo.ProductPrice }}</span>元</div>
                                <div class="cardtxt">
                                    <span v-for="(it, ind) in cardinfo.ProductSettingTipList
" :key="ind">{{ it.ProductTip }}</span>
                                    <div class="clear"></div>
                                </div>
                                <div class="cardtype">
                                    <div class="card" :class="item.Id == activevalue ? 'active' : ''"
                                        v-for="(item, index) in carddata" :key="index" @click.prevent="cardbtn(item)">
                                        {{ item.ProductName }}</div>
                                    <div class="clear"></div>
                                </div>
                                <div class="buybox">
                                    <div class="buybtn" @click="sell">立即购买</div>
                                    <a class="customer" v-if="ParamData.KF_Enable == '0'"
                                        @click="customerbtn(ParamData.KF_Url)"><i class="iconfont">&#xe601;</i>客服</a>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear"></div>
                        <div class="contents">
                            <div class="stit">服务详情</div>
                            <div v-html="cardinfo.ServiceDetailContent"></div>

                        </div>
                    </div>
                </div>
            </el-tab-pane>

            <!-- 激活 VIP -->
            <el-tab-pane label="激活（正式卡/体验卡）" name="2">
                <div class="actvip_box">
                    <div class="actvip_con">
                        <div class="actform">
                            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px"
                                class="demo-ruleForm">
                                <el-form-item label="卡号" prop="cardnumber">
                                    <el-input v-model="ruleForm.cardnumber" placeholder="请输入卡号" maxlength="20"
                                        @blur="checkCardNum"></el-input>
                                </el-form-item>
                                <el-form-item label="密码" prop="cardpassword">
                                    <el-input v-model="ruleForm.cardpassword" placeholder="请输入密码" :type="pwdtype"
                                        maxlength="20"></el-input>
                                    <i v-if="pwdstatus" class="iconfont pwd" @click.prevent="lookpwdbtn(false)">&#xe61b;</i>
                                    <i v-else class="iconfont pwd" @click.prevent="lookpwdbtn(true)">&#xe61c;</i>
                                </el-form-item>

                                <el-form-item v-if="examShowInfo" label="高考年份" prop="ExamYear">
                                    <el-date-picker v-model="ruleForm.ExamYear" type="year" placeholder="选择年"
                                        value-format="yyyy" clearable style="width:100%"
                                        @change="handleChangeYear"></el-date-picker>
                                </el-form-item>
                                <el-form-item v-if="examShowInfo" label="选科">
                                    <span class="tips" v-if="isCheack == false"><i
                                            class="iconfont">&#xe67b;</i>请先选择“高考年份”和“选科”</span>
                                    <div v-else>

                                        <div class="subject" v-for="(item, index) in ExamData.RuleList" :key="index">
                                            <span>{{ item.SelectExamTitle }}</span>
                                            <div class="subject-text">
                                                <el-checkbox-group v-model="item.selected" :max="item.MaxSelectCount">
                                                    <el-checkbox v-for="subItem in item.SelectExamSubjects" :key="subItem"
                                                        :label="subItem"></el-checkbox>
                                                </el-checkbox-group>
                                            </div>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item style="text-align: center;">
                                    <el-button type="primary" @click.prevent="submitForm('ruleForm')">激活</el-button>
                                    <el-button type="info" @click.prevent="clearForm('ruleForm')">清空</el-button>
                                </el-form-item>
                            </el-form>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

        <el-dialog :visible.sync="paymentDialog" width="23rem" center top="10vh" :close-on-click-modal="false"
            :close-on-press-escape="false" :before-close="handleClose">
            <div slot="title" class="payment-title">
                <img src="@/assets/pay-goods/weChatPaymentIcon.png" />微信扫码支付
            </div>
            <div class="payment-expire">支付倒计时：{{ countTime }}</div>
            <div class="payment-QRcode">
                <img :src="orderMainData.QRCode" alt="二维码图片" />
            </div>
            <ul class="payment-content">
                <li class="content-amount">￥{{ orderMainData.TotalFee }}</li>
                <li class="content-order-number">
                    订单号：{{ orderMainData.OrderNo }}
                </li>
                <li class="content-card">
                    {{ selectedCard.ProductName }}
                </li>
            </ul>
        </el-dialog>
        <exam-year ref="examYearInfo" @change="addInfo"></exam-year>
    </div>
</template>
<script>
import API from "@/api/config";
import { getStore } from "@/utils/utils";
import { Message } from 'element-ui';
import ExamYear from "./ExamYear.vue";
export default {
    components: {
        ExamYear
    },
    data() {
        return {

            tabactive: "1",//选项卡选中

            /********* 开通 VIP *********/
            activevalue: '',//vip卡类型选中
            cardinfo: {
                name: "",
                value: 0,
                price: 0,
                mark: [],
                img: ""
            },
            carddata: [],

            /********* 激活 VIP *********/
            pwdtype: 'password',
            pwdstatus: true,
            ruleForm: {
                cardnumber: '',
                cardpassword: '',
                ExamProvinceId: '',
                ExamYear: '',
                SubjectType: '',
                SelectSubjectList: [
                    {
                        SelectExamSubjects: []
                    },
                    {
                        SelectExamSubjects: []
                    }
                ],
            },
            ExamProvinceData: [],
            ExamData: {
                RuleList: []
            },
            isCheack: false,
            rules: {
                cardnumber: [
                    { required: true, message: '请输入卡号', trigger: 'blur' }
                ],
                cardpassword: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
                ExamProvinceId: [
                    { required: true, message: '请选择高考省份', trigger: 'change' }
                ],
                ExamYear: [
                    { required: true, message: '请选择高考年份', trigger: 'change' }
                ],
            },

            // 支付
            paymentDialog: false,
            orderMainData: "",
            timeID: null,
            newDate: "",
            countTime: "",
            timeReduce: "",
            hours: "",
            minutes: "",
            seconds: "",
            paymentStatusTimeID: null,

            selectedCard: {},
            ParamData: [],

            studentInfo: null,
            examShowInfo: false,

            urlParams: {}
        }
    },
    watch: {
        paymentDialog() {
            if (this.paymentDialog) {
                this.checkOrderStatus();
            } else {
                if (this.paymentStatusTimeID) {
                    clearInterval(this.paymentStatusTimeID);
                    this.paymentStatusTimeID = null;
                }
            }
        },
    },
    mounted() {
        let param = getStore("ParamList");
        if (param != null && param != undefined && param != '' && param != []) {
            JSON.parse(param).map(item => {
                this.$set(this.ParamData, item.ParamKey, item.ParamValue);
            });
        }
        this.getProductList()

        // 获取个人信息，判断卡号校验
        this.$store.dispatch('user/getStudentInfo').then(userInfo => {
            this.studentInfo = userInfo
            // 使用用户的考试省份
            this.ruleForm.ExamProvinceId = userInfo.UserScore.ExamProvinceId // 考试年份等信息已完善才回到选年份这一步

            this.isCheack = true;
        })

        let area = getStore('ProvinceList');
        if (area != null && area != undefined && area != '') {
            this.ExamProvinceData = JSON.parse(area);
        }

        // 处理默认选中tab
        let tempParams = this.$route.query
        this.urlParams = tempParams

        if (tempParams.name) {
            this.tabactive = tempParams.name
        }
    },
    methods: {
        // 校验卡号
        checkCardNum() {
            if (!this.ruleForm.cardnumber.trim()) {
                return
            }
            API.User.GetCardDetail({
                cardNum: this.ruleForm.cardnumber
            }).then(res => {

                if (res.data) {

                    API.User.CheckUserStudentComplate().then(({data: infoStatus}) => {
                        if (infoStatus.IsNeedComplateBaseInfo) {
                            this.$message.info('请先完善基本信息和高考信息！')
                            setTimeout(() => {
                                this.$router.push('/userinfo?redirect=/openvip&name=2')

                            }, 500)
                            return
                        }

                        if (!res.data.ExpireIn && !res.data.ExpireUnit) {

                            if (infoStatus.IsNeedComplateScore) {
                                this.$message.info('请先完善高考信息！')

                                setTimeout(() => {
                                    this.$router.push('/examinee?redirect=/openvip&name=2')

                                }, 500)

                            } else {
                                this.examShowInfo = true

                                if (this.studentInfo.UserScore) {
                                    let temp = this.ExamProvinceData.find(f=>f.ProvinceId===this.studentInfo.UserScore.ExamProvinceId)
                                    
                                    this.handleSubject(temp)
                                }
                            }
                        } else {
                            this.examShowInfo = false
                        }
                    })
                } else {
                    this.examShowInfo = false

                }
            })
        },

        // 处理选科
        handleSubject(ProviceItem) {
            this.$set(this.ExamData, 'RuleList', [])
            if (ProviceItem.EnableSubjectType) { // 有物理历史
                this.$set(this.ExamData.RuleList, 0, {
                    SelectExamTitle: '首选',
                    MaxSelectCount: 1,
                    SelectExamSubjects: ['物理', '历史'],
                    selected: []
                })
                this.$set(this.ExamData.RuleList, 1, {
                    SelectExamTitle: '再选',
                    MaxSelectCount: ProviceItem.SubjectOptionsCount,
                    SelectExamSubjects: ProviceItem.SubjectOptions.split(','),
                    selected: []
                })
            } else {
                this.$set(this.ExamData.RuleList, 0, {
                    SelectExamTitle: '',
                    MaxSelectCount: ProviceItem.SubjectOptionsCount,
                    SelectExamSubjects: ProviceItem.SubjectOptions.split(','),
                    selected: []
                })
            }
        },

        //客服
        customerbtn(url) {
            window.open(url);
        },


        addInfo(params) {

            this.submitOrder(params)
        },
        // 购买
        sell() {

            this.$confirm("是否购买此卡？", "提示", {
                type: "success",
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                confirmButtonClass: 'confirmButtonClass',
                cancelButtonClass: 'cancelButtonClass',
                center: true,
            }).then(() => {

                let loading = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0,0,0,0.5)'
                })

                if (!this.cardinfo.ExpireIn && !this.cardinfo.ExpireUnit) { // 需要完善
                    API.Personal.GetUserStudent().then(userInfo => {

                        if (userInfo.Data.Student === null) {
                            this.$message.info('请先完善基本信息和高考信息！')
                            this.$router.push('/userinfo?redirect=/openvip&name=1&productCode=' + this.cardinfo.ProductCode)

                            loading.close()
                        } else {
                            if (userInfo.Data.UserScore === null) {
                                this.$message.info('请先完善高考信息！')
                                this.$router.push('/examinee?redirect=/openvip&name=1&productCode=' + this.cardinfo.ProductCode)
                                loading.close()
                            } else {
                                this.submitOrder()
                            }
                        }
                    })
                } else { // z直接买
                    this.submitOrder()
                }
            }).catch((err) => { });
        },

        // 提交订单
        submitOrder(query) {

            let loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0,0,0,0.5)'
            })

            let params = {}
            if (query) {
                params = {
                    OrderType: 0,
                    ProductId: this.selectedCard.ProductId,
                    ProductCode: this.selectedCard.ProductCode,
                    ExamYear: query.ExamYear,
                    SubjectType: query.SubjectType,
                    SelectSubjectList: query.SelectSubjectList
                }
            } else {
                params = {
                    OrderType: 0,
                    ProductId: this.selectedCard.ProductId,
                    ProductCode: this.selectedCard.ProductCode,
                }
            }

            API.Payment.WechatQRCodePay(params).then((res) => {
                if (res.code === 0) {
                    this.orderMainData = res.data;
                    this.computeTime(this.orderMainData.PayExpire);
                } else {
                    this.$message({
                        type: "error",
                        message: res.message,
                    });
                }

                loading.close()
            }).catch((err) => {
                this.$message({
                    type: "error",
                    message: err.message,
                });
                loading.close()
            });
        },

        checkOrderStatus() {
            this.paymentStatusTimeID = setInterval(() => {
                API.Payment.PaymentStatus({
                    OrderNo: this.orderMainData.OrderNo,
                }).then((res) => {
                    if (res.data.PayStatus * 1 === 1) {
                        this.paymentDialog = false;
                        clearInterval(this.paymentStatusTimeID);
                        this.paymentStatusTimeID = null;
                        this.$message({
                            type: "success",
                            message: "支付成功!",
                        });

                        setTimeout(() => {
                            location.reload()
                        }, 2000)
                    }
                }).catch((err) => {
                    clearInterval(this.paymentStatusTimeID);
                    this.paymentStatusTimeID = null;
                    this.paymentDialog = false;
                    this.$message({
                        type: "error",
                        message: err.message,
                        duration: 3000,
                        offset: 30,
                        showClose: true,
                    });
                });
            }, 2000);
        },

        //format count down
        computeTime(expire) {
            this.newDate = parseInt(new Date().getTime() / 1000);
            this.timeReduce = expire * 1 - this.newDate * 1;

            if (!this.timeID) {
                this.paymentDialog = true;
                this.timeID = setInterval(() => {
                    this.timeReduce--;

                    if (this.timeReduce <= 0) {
                        clearInterval(this.timeID);
                        this.timeID = null;
                        this.paymentDialog = false;
                    } else {
                        this.hours = parseInt(this.timeReduce / 3600);
                        this.minutes = parseInt((this.timeReduce % 3600) / 60);
                        this.seconds = parseInt(this.timeReduce % 60);

                        if (this.minutes < 10) {
                            this.minutes = "0" + this.minutes;
                        }
                        if (this.seconds < 10) {
                            this.seconds = "0" + this.seconds;
                        }
                        this.countTime = `${this.hours}:${this.minutes}:${this.seconds}`;
                    }
                }, 1000);
            } else {
                this.timeID = null;
            }
        },

        handleClose(done) {
            done();
            setTimeout(() => {
                if (this.timeID) {
                    clearInterval(this.timeID);
                    this.timeID = null;
                    this.countTime = "";
                }
            }, 100);
        },

        // 获取产品列表
        getProductList() {
            API.Web.GetProductList().then(res => {
                this.carddata = res.Data
                let tempIndex = 0
                if (this.urlParams.productCode) {
                    let temp = this.carddata.some((item, index) => {
                        if (item.ProductCode === this.urlParams.productCode) {

                            this.activevalue = res.Data[index].Id;
                            this.selectedCard = res.Data[index]
                            tempIndex = index
                            return true
                        }
                    })
                    if (!temp) {
                        this.activevalue = res.Data[0].Id;
                        this.selectedCard = res.Data[0]
                    }
                } else {
                    this.activevalue = res.Data[0].Id;
                    this.selectedCard = res.Data[0]
                }

                this.getProductDetail(res.Data[tempIndex].ProductId)

            })
        },
        // 产品详情
        getProductDetail(params) {
            API.Web.GetProductDetail({ ProductId: params }).then(res => {
                if (res.Data != null) {
                    this.cardinfo = res.Data
                }
            })
        },

        /*********** 开通vip ***********/
        //开通vip卡类型选中
        cardbtn(t) {
            this.activevalue = t.Id;

            this.selectedCard = t

            this.getProductDetail(t.ProductId)

        },
        /*********** 开通vip ***********/

        /*********** 激活vip ***********/

        // 高考年份切换
        handleChangeYear(value) {

            this.$store.dispatch('user/getStudentInfo').then(userInfo => {
                this.studentInfo = userInfo
                // 使用用户的考试省份
                this.ruleForm.ExamProvinceId = userInfo.UserScore.ExamProvinceId // 考试年份等信息已完善才回到选年份这一步
                // 判断新老高考
                // this.$store.dispatch("user/judgeNewExam", {
                //     id: this.ruleForm.ExamProvinceId,
                //     year: value,
                // }).then(res => {

                //     if (res.IsNewExam) {
                //         if (!this.ExamData.IsNewExam || !this.ExamData.RuleList || this.ExamData.RuleList.length !== res.RuleList.length) { // refresh value

                //             res.RuleList = res.RuleList.map(item => {
                //                 item.selected = []
                //                 return item
                //             })

                //             this.ExamData = res
                //         }
                //     } else {
                //         if (this.ExamData.IsNewExam || !this.ExamData.RuleList) {

                //             let temp = [{
                //                 MaxSelectCount: 1,
                //                 SelectExamSubjects: ['文科', '理科'],
                //                 selected: []
                //             }]
                //             this.$set(this.ExamData, 'RuleList', temp)

                //             this.ExamData.IsNewExam = false

                //         }
                //     }
                // });
                this.isCheack = true;
            })
        },
        //激活vip
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {


                    // if (this.ExamData.IsNewExam === true) {

                    //     data = []
                    //     let flag1 = this.ExamData.RuleList.some(item => item.selected.length !== 0)

                    //     if (flag1) { // 已选
                    //         let flag2 = this.ExamData.RuleList.some(item => item.selected.length !== item.MaxSelectCount)
                    //         if (flag2) {
                    //             Message({ type: 'error', message: '请完善选科！' })
                    //             return
                    //         } else {
                    //             this.ExamData.RuleList.map(item => {
                    //                 data.push({
                    //                     SelectExamNum: item.SelectExamNum,
                    //                     SelectExamTitle: item.SelectExamTitle,
                    //                     SelectExamSubjects: item.selected
                    //                 })
                    //             })
                    //         }
                    //     }
                    // } else if (this.ExamData.IsNewExam === false) { // 老高考

                    //     if (this.ExamData.RuleList[0].selected.length !== 0) {
                    //         data = this.ExamData.RuleList[0].selected[0] === '文科' ? 1 : 2
                    //     }
                    // }

                    let s1=null,s2=null
                    let flag1 = this.ExamData.RuleList.some(item => item.selected.length !== 0)

                    if (flag1) { // 已选
                        let flag2 = this.ExamData.RuleList.some(item => item.selected.length !== item.MaxSelectCount)
                        if (flag2) {
                            Message({ type: 'error', message: '请完善选科！' })
                            return
                        } else {
                            if (this.ExamData.RuleList.length>1) {
                                if(this.ExamData.RuleList[0].selected=='物理') {
                                    s1 = 1
                                } else if (this.ExamData.RuleList[0].selected=='历史') {
                                    s1 = 2
                                } else {
                                    s1 = null
                                }

                                s2 = this.ExamData.RuleList[1].selected
                                s2 = s2.join()
                            } else {
                                s2 = this.ExamData.RuleList[0].selected
                                s2 = s2.join()
                            }
                        }
                    }

                    let param = {
                        CardNum: this.ruleForm.cardnumber,
                        Password: this.ruleForm.cardpassword,
                        ExamYear: this.ruleForm.ExamYear || null,
                        SubjectType: s1,
                        Subjects: s2
                    }

                    let textTips = this.examShowInfo?'注：高考省份、高考年份、选科激活后不允许修改，请确认无误':'确认是否激活'

                    this.$confirm(textTips, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        confirmButtonClass: 'confirmButtonClass',
                        cancelButtonClass: 'cancelButtonClass',
                        type: 'warning'
                    }).then(() => {

                        API.Personal.ActivationCard(param).then(res => {
                            if (res.Code === 0) {
                                this.clearForm('ruleForm');
                                this.examShowInfo = false

                                Message({ type: 'success', message: '激活成功！' });

                                setTimeout(() => {
                                    location.reload()
                                }, 1000)
                            }
                            else {
                                Message({ type: 'error', message: res.Message });
                            }
                        });
                    }).catch(() => {
                    });
                }
            });
        },
        //清空
        clearForm(formName) {
            this.$refs[formName].resetFields();
        },
        //密码显示切换
        lookpwdbtn(b) {
            this.pwdstatus = b;
            if (b) {
                this.pwdtype = "password";
            }
            else {
                this.pwdtype = "text";
            }
        },
        /*********** 激活vip ***********/
    }
}
</script>
<style lang="less" scoped>
.opena_ctivate {
    width: 100%;
    margin: auto;

    /**** 开通 VIP ****/
    .vip_box {
        width: 100%;
        background-color: #fff;
        padding: 50px 0px 30px;

        .vip_con {
            margin: 0px 20px;

            .cardbox {
                width: 100%;
                min-height: 282px;

                .cardimg {
                    width: 450px;
                    height: 100%;
                    float: left;
                    overflow: hidden;
                    border: 1px solid #e0e0e0;
                    border-radius: 15px;

                    img {
                        display: block;
                        width: 100%;
                    }
                }

                .cardmark {
                    width: calc(100% - 500px);
                    float: right;
                    height: 100%;
                    overflow: hidden;

                    .cardtit {
                        color: #333;
                        font-size: 24px;
                        font-weight: 400;
                    }

                    .price {
                        color: #8b8b8b;
                        font-size: 14px;
                        margin-top: 20px;

                        span {
                            font-size: 24px;
                            color: rgba(var(--themecolor), 1);
                            margin: 0px 10px;
                            font-weight: bold;
                        }
                    }

                    .cardtxt {
                        margin-top: 10px;
                        height: 50px;
                        overflow: hidden;

                        span {
                            color: #5a5a5a;
                            font-size: 12px;
                            background-color: #f8d5ad;
                            margin-right: 10px;
                            padding: 2px 15px;
                            border-radius: 20px;
                            margin-bottom: 5px;
                            display: inline-block;
                            user-select: none;
                        }
                    }

                    .cardtype {
                        margin-top: 10px;

                        .card {
                            width: 150px;
                            height: 48px;
                            line-height: 48px;
                            float: left;
                            border: 2px solid #d3d3d3;
                            color: #666;
                            font-size: 16px;
                            margin: 5px 0;
                            margin-right: 20px;
                            text-align: center;
                            transition: all 0.2s;
                            -webkit-transition: all 0.2s;
                            font-weight: bold;
                            user-select: none;
                        }

                        .card.active {
                            border: 2px solid rgba(var(--themecolor), 0.75);
                            color: rgba(var(--themecolor), 1);
                        }

                        .card:hover {
                            cursor: pointer;
                            color: rgba(var(--themecolor), 1);
                        }
                    }

                    .buybox {
                        margin-top: 28px;

                        .buybtn {
                            width: 328px;
                            height: 40px;
                            background-color: rgba(var(--themecolor), 0.75);
                            color: #fff;
                            font-size: 15px;
                            line-height: 40px;
                            text-align: center;
                            float: left;
                            transition: all 0.2s;
                            -webkit-transition: all 0.2s;
                            user-select: none;
                            margin-right: 30px;
                        }

                        .buybtn:hover {
                            cursor: pointer;
                            background-color: rgba(var(--themecolor), 1);
                        }

                        .customer {
                            width: 70px;
                            height: 22px;
                            line-height: 22px;
                            margin-top: 9px;
                            float: left;
                            display: block;
                            border: 1px solid rgba(var(--themecolor), 0.75);
                            background-color: rgba(var(--themecolor), 0.05);
                            color: rgba(var(--themecolor), 0.75);
                            font-size: 12px;
                            text-align: center;
                            border-radius: 20px;
                            user-select: none;

                            i {
                                font-size: 16px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }

            .contents {
                width: 100%;
                border: 1px solid #e4e4e4;
                border-radius: 10px;
                margin-top: 30px;

                .stit {
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                    color: #555;
                    font-size: 16px;
                    margin-top: 10px;
                }
            }
        }
    }

    /**** 激活 VIP ****/
    .actvip_box {
        width: 100%;
        padding: 50px 0px;
        background-color: #fff;

        .actvip_con {
            margin: 0px 20px;

            .actform {
                width: 100%;
                min-height: 400px;
                margin: 30px 0px;
            }
        }
    }
}

.payment-title {
    height: 2rem;
    line-height: 1.8;

    img {
        vertical-align: top;
        width: 2rem;
        height: 2rem;
    }
}

.payment-expire {
    text-align: center;
}

.payment-QRcode {
    text-align: center;
}

.payment-content {
    text-align: center;

    li {
        margin-bottom: 1rem;
    }

    .content-amount {
        color: red;
    }

    .content-card {
        height: 2rem;
        line-height: 2rem;
        background-color: rgba(var(--themecolor), 0.75);
        color: #fff;
    }
}
</style>
<style lang="less">
//选项卡
.opena_ctivate .el-tabs {
    margin: 10px;
    border: 1px solid #e2e2e2;
    border-top: none;
}

.opena_ctivate .el-tabs__header {
    margin: 0px 0px;
}

.opena_ctivate .el-tabs__nav {
    width: 100%;
    height: 50px;
    user-select: none;
}

.opena_ctivate .el-tabs__item {
    width: 50%;
    padding: 0;
    text-align: center;
    height: 100%;
    line-height: 48px;
    color: #666;
    font-size: 18px;
    font-weight: bold;
    background-color: #e0e0e0;
    border-top: 1px solid #e0e0e0;
}

.opena_ctivate .el-tabs__item.is-active {
    color: #666;
    background-color: #fff;
    border-top: 1px solid #ddd;
}

.opena_ctivate .el-tabs__item.is-focus {
    box-shadow: none !important;
}

.opena_ctivate .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none;
}

/* 激活VIP */
.actvip_box .actvip_con .actform .el-form .el-form-item {
    max-width: 80%;
    min-width: 400px;
}

.actvip_box .actvip_con .actform .el-form .el-form-item i.pwd {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 30px;
    text-align: center;
    font-size: 16px;
    color: #666;
    user-select: none;
}

.actvip_box .actvip_con .actform .el-form .el-form-item i.pwd:hover {
    cursor: pointer;
    color: rgb(var(--themecolor));
}

.actvip_box .actvip_con .actform .el-form .el-form-item .el-button--primary {
    width: 120px;
    background-color: rgba(var(--themecolor), 0.85);
    border-color: rgba(var(--themecolor), 0.85);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
}

.actvip_box .actvip_con .actform .el-form .el-form-item .el-button--primary:hover {
    background-color: rgba(var(--themecolor), 1);
    border-color: rgba(var(--themecolor), 1);
}

.actvip_box .actvip_con .actform .el-form .el-form-item .tips {
    color: #757575;
    font-size: 12px;

    i {
        margin-right: 5px;
    }
}

.actvip_box .el-form-item .el-input__inner:focus {
    border-color: rgba(var(--themecolor), 1);
}

.confirmButtonClass,
.confirmButtonClass:focus {
    background-color: rgba(var(--themecolor), 0.75);
    border-color: rgba(var(--themecolor), 0.75);
}

.confirmButtonClass:hover {
    background-color: rgba(var(--themecolor), 1);
    border-color: rgba(var(--themecolor), 1);
}

.cancelButtonClass {
    color: rgba(var(--themecolor), 0.75) !important;
}

.cancelButtonClass:hover,
.cancelButtonClass:focus {
    color: rgba(var(--themecolor), 0.75) !important;
    border-color: rgba(var(--themecolor), 1) !important;
}

.el-message-box__headerbtn:focus .el-message-box__close,
.el-message-box__headerbtn:hover .el-message-box__close {
    color: rgba(var(--themecolor), 0.75) !important;
}

.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
    color: rgba(var(--themecolor), 0.75) !important;
}
</style>
<template>
    <div class="exam-year">
        <el-dialog :visible.sync="showFlag" width="33rem" center top="20vh" :close-on-click-modal="false"
            :close-on-press-escape="false" :before-close="handleClose">
            <div slot="title" class="title">
                高考信息
            </div>
            <div class="box" v-loading="loading">
                <div>
                    <span class="title">高考年份：</span>
                    <el-date-picker v-model="year" @change="selectYear" :clearable="false" type="year" value-format="yyyy" placeholder="选择年份" :picker-options="pickerOptions"></el-date-picker>
                </div>
                <div class="subject">

                    <span class="title">选科：</span>
                    <div v-if="subjectData.IsNewExam">

                        <div class="item-item" v-for="(item, index) in subjectData.RuleList" :key="index">
                            <div class="name">{{ item.SelectExamTitle }}</div>
                            <el-checkbox-group v-model="item.selected" :max="item.MaxSelectCount">
                                <el-checkbox :label="subItem" v-for="subItem in item.SelectExamSubjects" :key="subItem">{{
                                    subItem }}</el-checkbox>
                            </el-checkbox-group>
                        </div>

                    </div>
                    <div v-else class="item">

                        <el-checkbox-group v-model="subjectType" :max="1">
                            <el-checkbox :label="1">文科</el-checkbox>
                            <el-checkbox :label="2">理科</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="submit">
                    <el-button @click="submitParams">确认</el-button>
                </div>
            </div>



        </el-dialog>
    </div>
</template>
<script>
import API from "@/api/config";
import { getStore } from '@/utils/utils'
export default {
    data() {
        return {
            showFlag: false,
            loading: false,

            info: {},
            year: null,

            subjectData: {
                IsNewExam: false
            },

            subjectType: [],

            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
            }
        }
    },

    mounted() {
       
    },
    methods: {

        submitParams() {
            if(!this.year) {

                this.$message.info('请选择年份！')
                return
            }
            let params = {
                ExamYear: Number(this.year),
            }

            if (this.subjectData.IsNewExam) {

                // 校验
                let flag = this.subjectData.RuleList.some(item => {
                    if (item.selected.length !== item.MaxSelectCount) {
                        this.$message.info('请完善选科！')
                        return true
                    }
                })
                if(flag) return

                params.SelectSubjectList = []
                this.subjectData.RuleList.forEach(item => {
                    params.SelectSubjectList.push({
                        SelectExamNum: item.SelectExamNum,
                        SelectExamTitle: item.SelectExamTitle,
                        SelectExamSubjects: item.selected
                    })
                })
            } else {
                if(this.subjectType.length === 0) {
                    this.$message.info('请完善选科！')

                    return
                } else {
                    params.SubjectType = this.subjectType[0]
                }
            }

            this.$emit('change', params)

            this.handleClose()
        },

        // 选择年份
        selectYear(params) {
            this.year = params
            this.loading = true
            this.$store.dispatch('user/judgeNewExam', {
                id: this.info.UserScore.ExamProvinceId,
                year: Number(params)
            }).then(res => {

                if (res.RuleList && res.RuleList.length > 0) {
                    res.RuleList = res.RuleList.map(item => {
                        item.selected = []

                        return item
                    })
                }

                this.subjectData = res
                this.loading = false

            })
        },

        show(userInfo) {
            this.info = userInfo
            this.showFlag = true

            this.loading = true
            this.year = String(this.info.UserScore.ExamYear)

            // 当前已有信息渲染
            this.$store.dispatch('user/judgeNewExam', {
                id: this.info.UserScore.ExamProvinceId,
                year: this.info.UserScore.ExamYear
            }).then(res => {

                if (res.IsNewExam) {

                    if (res.RuleList && res.RuleList.length > 0) {
                    res.RuleList = res.RuleList.map((item, index) => {
                        item.selected = []

                        this.$set(item, 'selected', this.info.UserScore.SelectSubjectList[index].SelectExamSubjects)

                        return item
                    })
                }
                } else {
                    this.subjectType = this.info.UserScore.SubjectType
                }

                this.subjectData = res
                this.loading = false
            })
        },

        // close
        handleClose() {

            this.showFlag = false
            this.loading = false
            this.year = null
            this.subjectData = {
                IsNewExam: false
            }
            this.subjectType = []
        }
    }
}
</script>
<style lang="less" scoped>
.box {
    min-height: 40vh;

    .subject {
        margin-top: 20px;

        .title {}

        .item {
            padding: 20px 40px;
        }

        .item-item {
            padding: 20px 40px;

            .name {
                margin-bottom: 10px;
            }
        }
    }
}

.submit {
    text-align: center;
}
</style>